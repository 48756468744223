<template>
  <div class="meeting-item" v-if="item">
    <div class="meeting-header">
      <div class="left">
        <div class="logo" v-if="item.logo_stream">
          <img :src="`${base64Map.get(item.logo.split('.').pop())}${item.logo_stream}`" alt="">
        </div>
        <div class="title-wrap">
          <div class="title" @click="handlePreview(item)">{{ item.title }}</div>
          <div class="year"><span>会议年份：</span><span>{{ item.meeting_year }}</span></div>
        </div>
      </div>
      <div class="right">
        <div class="download" @click="handleDownload">下载PDF</div>
      </div>
    </div>
    <div class="list-wrap">
      <div v-for="(item, index) in fileList" :key="index" class="list-item">
        <div class="index">题目: {{ index + 1 }}</div>
        <div class="title" @click="handleQuestion(item)">{{ item.title }}</div>
        <Icon style="cursor: pointer;" type="md-download" size="20" @click="handleFileClick(item)" />
      </div>
    </div>
    <div class="more" v-if="item.topics.length > 3" @click="handleMore">
      more
      <Icon type="ios-arrow-down" />
    </div>
  </div>
</template>

<script>
import { Icon, Message } from 'view-design'
import { saveAs } from 'file-saver'
import { downloadFile } from '@/api/meeting'
import { meetingTracking } from '@/api/tracking/'
export default {
  props: {
    item: {
      type: Object,
      default: () => null
    },
    activeIndex: {
      type: [Number, Object],
      default: () => null
    }
  },
  components: {
    Icon
  },
  data () {
    return {
      base64Map: new Map([
        ['png', 'data:image/png;base64,'],
        ['jpg', 'data:image/jpg;base64,'],
        ['jpeg', 'data:image/jpeg;base64,'],
        ['gif', 'data:image/gif;base64,'],
        ['bmp', 'data:image/bmp;base64,']
      ])
    }
  },
  computed: {
    fileList() {
      return this.activeIndex === this.item.id ? this.item.topics : this.item.topics.slice(0, 3)
    }
  },
  methods: {
    handleMore() {
      this.$emit('more')
    },
    handleDownload() {
      if (this.item.file.indexOf('http') === -1) {
        Message.warning('文件不存在')
        return
      }
      const fileName = decodeURIComponent(this.item.file).split('/').pop()
      downloadFile({ file: this.item.file }).then(res => {
        saveAs(res, fileName)
      })
    },
    handleFileClick(item) {
      if (item.file.indexOf('http') === -1) {
        Message.warning('文件不存在')
        return
      }
      meetingTracking({
        data_type: '主题下载',
        related_key: 'topic',
        topic: item.id
      })
      const fileName = decodeURIComponent(item.file).split('/').pop()
      downloadFile({ file: item.file }).then(res => {
        saveAs(res, fileName)
      })
    },
    handlePreview(item) {
      window.open(item.file_preview)
    },
    handleQuestion(item) {
      window.open(item.file_preview)
    }
  }
}
</script>

<style lang="less" scoped>
.meeting-item {
  padding: 30px 48px 0 48px;
  .meeting-header {
    display: flex;
    justify-content: space-between;
    .left {
      display: flex;

      .logo {
        width: 272px;
        height: 50px;
        margin-bottom: 12px;
        // background: #D8D8D8;
        margin-right: 15px;

        & > img {
          width: 100%;
          height: 100%;
          object-fit: contain;
        }
      }

      .title-wrap {
        .title {
          font-size: 18px;
          font-weight: bold;
          color: #202327;
          cursor: pointer;
        }
        .year {
          margin-top: 6px;
          font-size: 14px;
          color: #8E9CA9;
        }
      }
    }

    .right {
      display: flex;
      .download {
        padding: 0 16px;
        box-sizing: border-box;
        height: 32px;
        background: #FFFFFF;
        border-radius: 4px;
        line-height: 32px;
        border: 1px solid #D5D5D6;
        color: #202327;
        cursor: pointer;

        &:hover{
          color: #007FFF;
          border-color: #007FFF;
        }
      }
    }
  }

  .list-wrap {
    margin-top: 20px;
    .list-item {
      border: 1px solid #ccc;
      border-bottom: none;
      // margin-left: -1px;
      // margin-top: -1px;
      padding: 10px 32px;
      display: flex;
      align-items: center;

      &:last-child {
        border-bottom: 1px solid #ccc;
      }

      .index {
        color: #8E9CA9;
        margin-right: 8px;
      }

      .title {
        flex: 1;
        color: #0082FF;
        cursor: pointer;
      }
    }
  }

  .more {
    margin-top: 12px;
    color: #007FFF;
    cursor: pointer;
  }
}
</style>