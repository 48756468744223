<template>
  <div class="meeting-wrap">
    <div class="meeting-header">
      <div class="title">
        <i class="iconfont iconxinxiku"></i>
        <span>会议速递</span>
        <span class="slash" v-if="filterText !== ''">/</span>
        <div class="filter-wrap" v-if="filterText !== ''">{{ filterText }}</div>
      </div>
      <div class="clear" @click="handleClear"><i class="iconfont iconshanchu"></i>清空筛选</div>
    </div>
    <Row :gutter="16" style="margin-top: 20px;">
      <Col span="6">
        <div class="card">
          <div class="title">领域</div>
          <FilterCom v-model="meetingFieldIds" @change="handleFieldChange" :list="meetingField" />
        </div>
        <div class="card">
          <div class="title">生物标志物</div>
          <FilterCom v-model="meetingBiomarkerIds" @change="handleBiomarkerChange" :list="meetingBiomarker" />
        </div>
        <div class="card">
          <div class="title">会议</div>
          <FilterCom v-model="meetingLabelListIds" @change="handleLabelChange" :list="meetingLabelList" />
        </div>
        <div class="card">
          <div class="title">年份</div>
          <div class="year-wrap">
            <DatePicker type="year" placeholder="开始年份" @on-change="handleStart" style="width: 100px"></DatePicker>
            <div class="line"></div>
            <DatePicker type="year" placeholder="结束年份" @on-change="handleEnd" style="width: 100px"></DatePicker>
          </div>
        </div>
      </Col>
      <Col span="18">
        <div class="search-wrap">
          <Input v-model="search" style="margin-right: 16px;" size="large" placeholder="请输入关键词进行搜索" />
          <Button type="primary" size="large" ghost @click="handleSearch">搜索</Button>
        </div>
        <div class="content-wrap">
          <div class="content-header">
            <div class="total">
              共<span class="num">{{ total }}</span
              >条数据
            </div>
            <div style="display: flex;align-items: center;">
              <div class="sort-wrap">
                <div style="display: flex; align-items: center; color: #8E9CA9;">排序：</div>
                <div class="sort-item" @click="handleSort">
                  会议年份
                  <i
                    class="iconfont iconbianzu22"
                    :style="{ color: this.ordering === 'meeting_year,-id' ? '#007FFF' : '#656e77' }"
                  ></i>
                  <i
                    class="iconfont iconbianzu21"
                    :style="{
                      marginLeft: '-8px',
                      color: this.ordering === '-meeting_year,-id' ? '#007FFF' : '#656e77'
                    }"
                  ></i>
                </div>
              </div>
              <!-- <Divider type="vertical"/>
              <Tooltip content="导出">
                <i class="iconfont iconxiazai1 download"></i>
              </Tooltip> -->
            </div>
          </div>
          <MeetingItem
            v-for="item in meetingList"
            :key="item.id"
            :item="item"
            :activeIndex="activeIndex"
            @more="handleMore(item.id)"
          />
          <div v-if="meetingList.length === 0" class="empty-wrap">
            <img src="../../assets/imgs/empty.png" alt="" />
          </div>
          <div style="display: flex; justify-content: flex-end; margin: 16px 0; padding: 0 48px;">
            <Page :total="total" :page-size="5" @on-change="handlePageChange" />
          </div>
        </div>
      </Col>
    </Row>
  </div>
</template>

<script>
import { fetchMeetingField, fetchMeetingBiomarker, fetchMeetingLabelList, fetchMeetingList } from '@/api/meeting'
import { Row, Col, Input, Button, Page, DatePicker } from 'view-design'
import FilterCom from './filter'
import MeetingItem from './meetingItem'

export default {
  components: {
    Row,
    Col,
    Input,
    Button,
    Page,
    MeetingItem,
    DatePicker,
    FilterCom
  },
  data() {
    return {
      meetingField: [],
      meetingFieldIds: [],
      meetingBiomarker: [],
      meetingBiomarkerIds: [],
      meetingLabelList: [],
      meetingLabelListIds: [],
      startYear: '',
      endYear: '',
      total: 0,
      page: 1,
      ordering: '',
      meetingList: [],
      activeIndex: null,
      search: ''
    }
  },
  created() {
    this.filterFetch()
    this.fetchList()
  },
  computed: {
    filterText() {
      const fieldNames = this.meetingFieldIds.map(item => {
        return this.meetingField.find(field => field.id === item).name
      })
      const biomarkerNames = this.meetingBiomarkerIds.map(item => {
        return this.meetingBiomarker.find(field => field.id === item).name
      })
      const labelNames = this.meetingLabelListIds.map(item => {
        return this.meetingLabelList.find(field => field.id === item).name
      })
      return [...fieldNames, ...biomarkerNames, ...labelNames].join('-')
    }
  },
  methods: {
    filterFetch() {
      fetchMeetingField({
        search: this.search,
        ordering: this.ordering,
        meeting_id__in: this.meetingLabelListIds.join(','),
        field__id__in: this.meetingFieldIds.join(','),
        topics__bio_marker__id__in: this.meetingBiomarkerIds.join(','),
        meeting_year__gte: this.startYear,
        meeting_year__lte: this.endYear,
        t: new Date().getTime()
      }).then(res => {
        if (res.code === 200) {
          this.meetingField = res.data
        }
      })
      fetchMeetingBiomarker({
        search: this.search,
        ordering: this.ordering,
        meeting_id__in: this.meetingLabelListIds.join(','),
        field__id__in: this.meetingFieldIds.join(','),
        topics__bio_marker__id__in: this.meetingBiomarkerIds.join(','),
        meeting_year__gte: this.startYear,
        meeting_year__lte: this.endYear,
        t: new Date().getTime()
      }).then(res => {
        if (res.code === 200) {
          this.meetingBiomarker = res.data
        }
      })
      fetchMeetingLabelList({
        search: this.search,
        ordering: this.ordering,
        meeting_id__in: this.meetingLabelListIds.join(','),
        field__id__in: this.meetingFieldIds.join(','),
        topics__bio_marker__id__in: this.meetingBiomarkerIds.join(','),
        meeting_year__gte: this.startYear,
        meeting_year__lte: this.endYear,
        t: new Date().getTime()
      }).then(res => {
        if (res.code === 200) {
          this.meetingLabelList = res.data
        }
      })
    },
    fetchList() {
      fetchMeetingList({
        page: this.page,
        page_size: 5,
        search: this.search,
        ordering: this.ordering,
        meeting_id__in: this.meetingLabelListIds.join(','),
        field__id__in: this.meetingFieldIds.join(','),
        topics__bio_marker__id__in: this.meetingBiomarkerIds.join(','),
        meeting_year__gte: this.startYear,
        meeting_year__lte: this.endYear,
        t: new Date().getTime()
      }).then(res => {
        if (res.code === 200) {
          this.meetingList = res.data
          this.total = res.page.count
        }
      })
    },
    handlePageChange(page) {
      this.page = page
      this.fetchList()
    },
    handleMore(index) {
      if (this.activeIndex === index) {
        this.activeIndex = null
      } else {
        this.activeIndex = index
      }
    },
    handleSearch() {
      this.fetchList()
    },
    handleFieldChange(val) {
      this.meetingFieldIds = val
      this.fetchList()
      this.filterFetch()
    },
    handleBiomarkerChange(val) {
      this.meetingBiomarkerIds = val
      this.fetchList()
      this.filterFetch()
    },
    handleLabelChange(val) {
      this.meetingLabelListIds = val
      this.fetchList()
      this.filterFetch()
    },
    handleSort() {
      if (this.ordering === '') {
        this.ordering = '-meeting_year,-id'
      } else if (this.ordering === '-meeting_year,-id') {
        this.ordering = 'meeting_year,-id'
      } else {
        this.ordering = ''
      }
      this.fetchList()
    },
    handleClear() {
      this.meetingFieldIds = []
      this.meetingBiomarkerIds = []
      this.meetingLabelListIds = []
      this.startYear = ''
      this.endYear = ''
      this.search = ''
      this.fetchList()
      this.filterFetch()
    },
    handleStart(date) {
      this.startYear = date
      this.fetchList()
    },
    handleEnd(date) {
      this.endYear = date
      this.fetchList()
    }
  }
}
</script>

<style lang="less" scoped>
.meeting-wrap {
  min-height: calc(100vh - 230px);
  padding-top: 20px;

  .meeting-header {
    color: #969fa8;
    font-size: 16px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .title {
      cursor: pointer;
      display: flex;
      align-items: center;

      & > i {
        margin-right: 4px;
      }

      .slash {
        margin: 0 8px;
      }

      .filter-wrap {
        color: #656e77;
        padding: 5px 20px;
        background: rgba(0, 127, 255, 0.08);
        border-radius: 15px;
      }
    }

    .clear {
      width: 116px;
      height: 32px;
      border-radius: 6px;
      border: 1px solid #007fff;
      font-size: 16px;
      display: flex;
      align-items: center;
      justify-content: center;
      color: #007fff;
      cursor: pointer;

      & > i {
        margin-right: 8px;
      }
    }
  }

  .card {
    padding: 24px 32px;
    background: #ffffff;
    border-radius: 12px;
    margin-bottom: 16px;

    .title {
      font-size: 16px;
      color: #202327;
    }
  }

  .year-wrap {
    display: flex;
    align-items: center;
    margin-top: 14px;

    .line {
      width: 15px;
      height: 1px;
      margin: 0 10px;
      background-color: #dbe2e6;
    }
  }

  .search-wrap {
    background: #ffffff;
    border-radius: 12px;
    padding: 20px 32px;
    display: flex;
  }

  .content-wrap {
    background: #ffffff;
    border-radius: 12px;
    margin-top: 16px;
    margin-bottom: 24px;
    padding-bottom: 24px;

    .content-header {
      padding: 16px 32px;
      display: flex;
      justify-content: space-between;
      border-bottom: 1px solid #e8ecef;

      .total {
        font-size: 14px;
        color: #656e77;

        .num {
          font-weight: bold;
          margin: 0 4px;
        }
      }

      .sort-wrap {
        display: flex;

        .sort-item {
          display: flex;
          align-items: center;
          cursor: pointer;

          & > i {
            font-weight: 500;
            font-size: 15px;
          }
        }
      }

      .download {
        color: #8e9ca9;
        cursor: pointer;
        &:hover {
          color: #007fff;
        }
      }
    }
  }
}

.empty-wrap {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 40px;
  margin-bottom: 40px;
}
</style>
